import 'react-datepicker/dist/react-datepicker.css'
import './LocalizedDatePicker.scss'

import { Label } from '@lk/lk-design-system'
import classNames from 'classnames'
import ca from 'date-fns/locale/ca'
import es from 'date-fns/locale/es'
import eu from 'date-fns/locale/eu'
import React, { FC, useMemo, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

const calendarIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="10" width="16" height="2" fill="#999999" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6C4.44772 6 4 6.44772 4 7V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V7C20 6.44772 19.5523 6 19 6H5ZM2 7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7Z"
      fill="#999999"
    />
    <rect x="7" y="0" width="2" height="6" rx="1" fill="#999999" />
    <rect x="15" y="0" width="2" height="6" rx="1" fill="#999999" />
  </svg>
)

export interface LocalizedDatePickerProps {
  label: string
  language: string
  onChange: (date: Date) => void
  placeholder?: string
  selected?: Date
  required?: boolean
  minDate?: Date
  maxDate?: Date
  startDate?: Date
  endDate?: Date
  error?: boolean
  errorMessage?: string
  disabled?: boolean
}

registerLocale('es', es)
registerLocale('eu', eu)
registerLocale('ca', ca)

const LocalizedDatePicker: FC<LocalizedDatePickerProps> = ({
  language,
  label,
  placeholder,
  onChange,
  error,
  errorMessage,
  selected,
  required,
  minDate,
  maxDate,
  startDate,
  endDate,
  disabled,
}) => {
  const datePickerRef = React.createRef<DatePicker>()
  const labelId = useMemo(() => `date-picker-${Math.random().toString(36)}`, [])

  const [currDate, setCurrDate] = useState<Date>(selected)
  const dateFormat = language === 'eu' ? 'yyyy/MM/dd' : 'dd/MM/yyyy'
  const datePlaceholder = dateFormat.replace('yyyy', 'aaaa')
  return (
    <div className="lk-input">
      <Label id={labelId} required={required} label={label} classLabel={classNames({ 'lk-label--error': error })} />
      <DatePicker
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        title=""
        minDate={minDate}
        maxDate={maxDate}
        id={labelId}
        ref={datePickerRef}
        dateFormat={dateFormat}
        toggleCalendarOnIconClick
        ariaLabeledBy={labelId}
        onChange={(e) => {
          setCurrDate(e)
          onChange(e)
        }}
        locale={language}
        selected={currDate}
        placeholderText={placeholder || datePlaceholder.toLowerCase()}
        required={required}
        className={classNames('lk-input__box', {
          'lk-input__box--error': error,
          'lk-datepicker--disabled': disabled,
        })}
        wrapperClassName="localized-date-picker"
        showIcon
        icon={calendarIcon}
        startDate={startDate}
        endDate={endDate}
        disabled={disabled}
      />
      {error && <Label label={errorMessage} classLabel="lk-input__error lk-label--error" />}
    </div>
  )
}

LocalizedDatePicker.defaultProps = {
  selected: undefined,
  required: false,
  placeholder: '',
  minDate: undefined,
  maxDate: undefined,
  startDate: undefined,
  endDate: undefined,
  error: false,
  errorMessage: '',
  disabled: false,
}

export default LocalizedDatePicker
