import './Information.scss'

import React from 'react'
import { Trans } from 'react-i18next'

const advantages = ['world', 'travel', 'account']

const InitialInformation = () => (
  <div className="lk-calculator__aside  col-lg-4 col-md-12 bg-berenjena-claro-4">
    <div className="advantages-box">
      <p className="advantages-box__title text-large strong">
        <Trans i18nKey="widgetInsuranceCalculator.advantagesInitialForm.title" components={{ b: <b /> }} />
      </p>
      <div className="advantages-box__content">
        {advantages.map((advantage) => (
          <div key={`initial_${advantage}`} className="advantages-box__content-item">
            <img
              className="advantages-box__content-item__icon"
              src={`/Components/WidgetInsuranceCalculator/${advantage}.png`}
              alt={advantage}
            />
            <div>
              <Trans
                className="text-large"
                i18nKey={`widgetInsuranceCalculator.advantagesInitialForm.${advantage}`}
                components={{ b: <b /> }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default InitialInformation
