import './WidgetLogout.scss'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const WidgetLogout: React.FC = () => {
  const { t } = useTranslation()
  const [name, setName] = useState('')

  useEffect(() => {
    if (typeof window === 'undefined' || !window.location) {
      return
    }
    const params = new URL(window.location.href)
    const userName = params.searchParams.get('nombre_usuario')
    if (userName) {
      setName(decodeURIComponent(userName))
    }
  }, [])

  return (
    <div data-testid="lk-widget-logout" className="widget-logout">
      <img src="/Components/WidgetLogout/logout.png" alt="" />
      <div>
        <p className="heading-large">{t('widgetLogout.goodbye', { name })}</p>
        <p className="text-large">{t('widgetLogout.youHaveBeenDisconnected')}</p>
      </div>
    </div>
  )
}

export default WidgetLogout
