import { Button } from '@lk/lk-design-system'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../../hooks/useI18nUtils'
import {
  InsuranceAccidentQuotaModules,
  InsuranceAccidentQuotaProfessions,
  PatchInsuranceAccidentQuotaArgs,
  PatchInsuranceAccidentQuotaOptions,
} from '../../../../../services/lk-apis/types'
import AccessibleSelect from '../../../../AccessibleSelect/AccessibleSelect'
import LocalizedDatePicker from '../../../../LocalizedDatePicker/LocalizedDatePicker'

export interface ConfortProps {
  previousBirthDate: Date
  modules: InsuranceAccidentQuotaModules[]
  professions: InsuranceAccidentQuotaProfessions[]
  buttonRole: 'primary' | 'secondary'
  onSubmit: (values: PatchInsuranceAccidentQuotaArgs, option: PatchInsuranceAccidentQuotaOptions) => void
  onModuleChange: (module: string) => void
}

const InsuranceCalculatorConfort: FC<ConfortProps> = ({
  previousBirthDate,
  modules,
  professions,
  buttonRole,
  onSubmit,
  onModuleChange,
}) => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)

  const { formatCurrency } = useI18nUtils()

  const [selectedModule, setSelectedModule] = useState('')
  const [selectedProfession, setSelectedProfession] = useState('')

  const handleProfessionChange = useCallback(
    (value) => {
      if (value !== selectedProfession) {
        setSelectedProfession(value)
      }
    },
    [selectedProfession],
  )

  const handleModuleChange = useCallback(
    (value) => {
      if (value !== selectedModule) {
        setSelectedModule(value)
        onModuleChange(value)
      }
    },
    [selectedModule],
  )

  const handleSubmit = useCallback(() => {
    if (!selectedModule || !selectedProfession) return
    onSubmit(
      {
        moduleCode: parseInt(selectedModule, 10),
        professionCode: parseInt(selectedProfession, 10),
        professionTarificable: professions.find((profession) => profession.code === parseInt(selectedProfession, 10))
          ?.tarificable,
        includeDecease: false,
      },
      'GenerateBudgets',
    )
  }, [onSubmit, selectedModule, selectedProfession])

  const moduleOptions = modules.map((module) => ({
    label: formatCurrency(module.moduleImport.amount, module.moduleImport.currency),
    value: module.moduleCode.toString(),
  }))
  const professionsOptions = professions.map((profession) => ({
    label: profession.description,
    value: profession.code.toString(),
  }))
  return (
    <>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <LocalizedDatePicker
            language={language}
            label={t('widgetInsuranceCalculator.age')}
            onChange={() => undefined}
            selected={previousBirthDate}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <AccessibleSelect
            name="modules"
            label={t('widgetInsuranceCalculator.quantity')}
            options={moduleOptions}
            initialValue=""
            onItemSelected={handleModuleChange}
            placeholder={t('widgetInsuranceCalculator.selectQuantity')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <AccessibleSelect
            name="professions"
            label={t('widgetInsuranceCalculator.profession')}
            options={professionsOptions}
            initialValue=""
            onItemSelected={handleProfessionChange}
            placeholder={t('widgetInsuranceCalculator.selectProfession')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 lk-widget-insurance-calculator__actions">
          <Button
            type="button"
            buttonRole={buttonRole}
            label={t('widgetInsuranceCalculator.offerButton')}
            onClick={handleSubmit}
            disabled={!selectedProfession || !selectedModule}
          />
        </div>
      </div>
    </>
  )
}

export default InsuranceCalculatorConfort
