import './InsuranceCalculatorFinalForm.scss'

import { Button, Input } from '@lk/lk-design-system'
import React, { FC, useCallback, useReducer, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  InsuranceAccidentQuotaModules,
  PatchInsuranceAccidentQuotaBudget,
  PatchInsuranceAccidentQuotaCustomer,
  PatchInsuranceAccidentQuotaOptions,
} from '../../../../../services/lk-apis/types'
import { DocWithSlug } from '../../../../CTALink/CTALink'
import LegalCheckbox from '../../../../LegalCheckbox/LegalCheckbox'
import useBolUtils from '../../WidgetBolNewUser/common/useBolUtils'
import InsuranceCalculatorOffers from './InsuranceCalculatorOffers'

interface InsuranceCalculatorOfferBoxProps {
  isConfort: boolean
  currentBudget: PatchInsuranceAccidentQuotaBudget
  selectedBudget: InsuranceAccidentQuotaModules
  legalLink: DocWithSlug
  legalLinkPreContractual: DocWithSlug
  onSubmit: (values: PatchInsuranceAccidentQuotaCustomer, option: PatchInsuranceAccidentQuotaOptions) => void
  onChangeBudget: (newSelectedBudget: InsuranceAccidentQuotaModules) => void
  selectedDeseace: boolean
}
const initialFormValues: PatchInsuranceAccidentQuotaCustomer = {
  email: '',
  fullName: '',
  mobileNumber: '',
}

const InsuranceCalculatorFinalForm: FC<InsuranceCalculatorOfferBoxProps> = ({
  isConfort,
  currentBudget,
  legalLink,
  legalLinkPreContractual,
  selectedBudget,
  onSubmit,
  onChangeBudget,
  selectedDeseace,
}) => {
  const { t } = useTranslation()
  const { isValidField } = useBolUtils()
  const [validationErrors, setValidationError] = useReducer(
    (
      prev: PatchInsuranceAccidentQuotaCustomer,
      newData: Partial<PatchInsuranceAccidentQuotaCustomer>,
    ): PatchInsuranceAccidentQuotaCustomer => ({
      ...prev,
      ...newData,
    }),
    initialFormValues,
  )

  const [legalAccepted, setLegalAccepted] = useState(false)
  const [legalPreContractualAccepted, setLegalPreContractualAccepted] = useState(false)
  const [userInformation, setUserInformation] = useReducer(
    (prev: PatchInsuranceAccidentQuotaCustomer, newData: Partial<PatchInsuranceAccidentQuotaCustomer>) => ({
      ...prev,
      ...newData,
    }),
    initialFormValues,
  )

  const thereIsSuperiorBudget = !!currentBudget?.superiorBudgetedModule

  const isLegalAccepted = legalAccepted && legalPreContractualAccepted
  const isBudgetSelected = currentBudget && selectedBudget
  const isUserInformationComplete = userInformation.email && userInformation.mobileNumber && userInformation.fullName

  const isFormComplete = isLegalAccepted && isBudgetSelected && isUserInformationComplete

  const validateField = (fieldName: string, validatorName: string) => {
    if (!isValidField(validatorName, userInformation[fieldName])) {
      setValidationError({ [fieldName]: t(`widgetInsuranceCalculator.errors.${fieldName}`) })
    }
  }

  const handleSubmit = useCallback(() => {
    if (!legalAccepted || !legalPreContractualAccepted) {
      return
    }
    validateField('fullName', 'name')
    validateField('email', 'email')
    validateField('mobileNumber', 'mobile')

    if (Object.values(validationErrors).some((error) => error !== '')) {
      return
    }

    onSubmit(userInformation, 'ContractOption')
  }, [userInformation, onSubmit, legalAccepted, legalPreContractualAccepted])

  return (
    <div className="lk-widget-insurance-calculator__contact">
      <InsuranceCalculatorOffers
        isConfort={isConfort}
        currentBudget={currentBudget}
        onChangeBudget={onChangeBudget}
        selectedDeseace={selectedDeseace}
      />
      <div className={`col-lg-${thereIsSuperiorBudget ? '4' : '6'} col-md-12`}>
        <div className="lk-widget-insurance-calculator__contact-form">
          <p className="lk-widget-insurance-calculator__contact-form-title strong">
            <Trans
              i18nKey={`widgetInsuranceCalculator.subtitle.contact${isConfort ? 'Confort' : 'SuperLk'}`}
              components={{ b: <b /> }}
            />
          </p>
          <Input
            label={t('widgetInsuranceCalculator.fullName')}
            type="text"
            aria-label={t('widgetInsuranceCalculator.fullName')}
            onChange={(e) => setUserInformation({ fullName: e.target.value })}
            error={!!validationErrors.fullName}
            errorMessage={t(validationErrors.fullName)}
            disabled={!selectedBudget}
            required
          />
          <Input
            label={t('widgetInsuranceCalculator.email')}
            type="email"
            aria-label={t('widgetInsuranceCalculator.email')}
            onChange={(e) => setUserInformation({ email: e.target.value })}
            error={!!validationErrors.email}
            errorMessage={t(validationErrors.email)}
            disabled={!selectedBudget}
            required
          />
          <Input
            label={t('widgetInsuranceCalculator.mobileNumber')}
            type="tel"
            aria-label={t('widgetInsuranceCalculator.mobileNumber')}
            onChange={(e) => setUserInformation({ mobileNumber: e.target.value })}
            error={!!validationErrors.mobileNumber}
            errorMessage={t(validationErrors.mobileNumber)}
            disabled={!selectedBudget}
            required
          />
          <div className="lk-widget-insurance-calculator__legal">
            <LegalCheckbox
              fieldName="legal"
              legalTitle={t('widgetInsuranceCalculator.legalCheck')}
              legalLink={`/${legalLink?.slug?.current}`}
              disabled={!selectedBudget}
              onLegalChange={(_, accepted) => setLegalAccepted(accepted)}
            />
            <LegalCheckbox
              fieldName="legal-pre-contractual"
              legalTitle={t('widgetInsuranceCalculator.legalPreContractualCheck')}
              legalLink={`/${legalLinkPreContractual?.slug?.current}`}
              disabled={!selectedBudget}
              onLegalChange={(_, accepted) => setLegalPreContractualAccepted(accepted)}
            />
          </div>
          <Button
            buttonRole="primary"
            label={t('widgetInsuranceCalculator.contactWithOffer')}
            type="button"
            onClick={handleSubmit}
            disabled={!isFormComplete}
          />
        </div>
      </div>
    </div>
  )
}

export default InsuranceCalculatorFinalForm
