import '../Widgets.scss'
import './WidgetDeceaseInsurance.scss'

import { Button, Icon, Input } from '@lk/lk-design-system'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GtmEvents, useSeoTelemetry } from '../../../../context/SeoTelemetry/SeoTelemetryContext'
import useI18nUtils from '../../../../hooks/useI18nUtils'
import { DataLayer } from '../../../../lib/types/sanity-schema'
import LKApis from '../../../../services/lk-apis/LKApisProvider'
import { PostInsuranceFuneralQuotaResponseData } from '../../../../services/lk-apis/types'
import AccessibleModal from '../../../AccessibleModal/AccessibleModal'
import { DocWithSlug } from '../../../CTALink/CTALink'
import LegalCheckbox from '../../../LegalCheckbox/LegalCheckbox'
import LocalizedDatePicker from '../../../LocalizedDatePicker/LocalizedDatePicker'
import widgetDeceaseInsuranceEvents from './constants/widgetDeceaseInsuranceEvents'

export interface WidgetDeceaseInsuranceProps {
  legalLink: DocWithSlug
  lkWidgetsDataLayer: DataLayer
}

const firstInsuredMinAge = 18
const otherInsuredMinAge = 0
const firstInsuredIndex = 0
const maxAge = 65

type FieldErrors = {
  postalCode?: string
  birthDate_0?: string
  birthDate_1?: string
  birthDate_2?: string
  birthDate_3?: string
  birthDate_4?: string
  fullName?: string
  identifier?: string
  email?: string
  mobile?: string
  legal?: string
}

const WidgetDeceaseInsurance: FC<WidgetDeceaseInsuranceProps> = ({ legalLink, lkWidgetsDataLayer }) => {
  const { t } = useTranslation()
  const { lkWebApi } = useContext(LKApis)
  const { formatCurrency } = useI18nUtils()
  const { pushToGoogleTagManager } = useSeoTelemetry()
  const { getUserAge } = useI18nUtils()

  const [postalCode, setPostalCode] = useState('')
  const [birthDates, setBirthDates] = useState<string[]>([''])
  const [insured, setInsured] = useState([''])
  const [sentOk, setSentOk] = useState(false)
  const [apiError, setApiError] = useState('')
  const [legalAccepted, setLegalAccepted] = useState(false)

  const [fullName, setFullName] = useState('')
  const [identifier, setIdentifier] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')

  const [response, setResponse] = useState<PostInsuranceFuneralQuotaResponseData | null>(null)

  const errorsReducer = (state: FieldErrors, error: FieldErrors): FieldErrors => (!error ? {} : { ...state, ...error })
  const [validationErrors, setValidationError] = useReducer(errorsReducer, {})
  const { language } = useContext(I18nextContext)

  const clearData = useCallback(() => {
    setResponse(null)
    setPostalCode('')
    setBirthDates([''])
    setFullName('')
    setIdentifier('')
    setEmail('')
    setMobile('')
    setLegalAccepted(false)
    setValidationError({})
  }, [])

  const sendDataLayer = (event, stepData = {}, extraData = {}, includeCommonFields = true) => {
    const commonData = includeCommonFields
      ? {
          edad: getUserAge(new Date(birthDates[firstInsuredIndex])).toString(),
          codigo_postal: postalCode,
          numero_asegurados: insured.length.toString(),
        }
      : {}

    const dataLayer = {
      event,
      data: {
        ...widgetDeceaseInsuranceEvents.general,
        ...stepData,
        ...commonData,
        ...(extraData || {}),
        ...(lkWidgetsDataLayer?.dataLayer ? JSON.parse(lkWidgetsDataLayer.dataLayer) : {}),
      },
      sendUserData: lkWidgetsDataLayer?.userData ?? false,
      pageLocation: lkWidgetsDataLayer?.pageLocation ?? false,
    }

    pushToGoogleTagManager(dataLayer)
  }

  const sendErrorDataLayer = (errorMessage) => {
    if (!errorMessage) return
    sendDataLayer(GtmEvents.SIMULATOR_ERROR_MESSAGE, {}, { simulator_error: errorMessage }, false)
  }

  const sendCalculateDataLayer = () => {
    sendDataLayer(GtmEvents.SIMULATOR_PROCESS, widgetDeceaseInsuranceEvents.step_01)
  }

  const sendResultDataLayer = (extraData = {}) => {
    sendDataLayer(GtmEvents.SIMULATOR_COMPLETED, widgetDeceaseInsuranceEvents.step_02, extraData)
  }

  const sendGenerateLeadDataLayer = (extraData = {}) => {
    sendDataLayer(GtmEvents.GENERATE_LEAD, widgetDeceaseInsuranceEvents.step_03, extraData)
  }

  const validateBirthDate = useCallback((birthDate, minAge, index): boolean => {
    let error
    const ageDiff = new Date(Date.now() - new Date(birthDate).getTime()).getFullYear() - 1970
    if (!birthDate) {
      error = 'errors.required'
    } else if (ageDiff < 0) {
      error = 'errors.invalid.date'
    } else if (ageDiff > maxAge) {
      error = 'widgetDeceaseInsurance.errors.tooOld'
    } else if (index < 1 && ageDiff < minAge) {
      error = `errors.invalid.mustBeAdult`
    }
    const key = `birthDate_${index}`
    const newErrors: { [key: string]: string } = {}
    newErrors[key] = error
    setValidationError(newErrors)
    Object.values(newErrors).map((currentError) => sendErrorDataLayer(t(currentError)))
    return !error
  }, [])

  const validatePostalCode = useCallback((cp: string): boolean => {
    let error
    if (!cp) {
      error = 'errors.invalid.postalCode'
    }
    setValidationError({ postalCode: error })
    sendErrorDataLayer(t(error))
    return !error
  }, [])

  const validateMobile = useCallback((v: string): boolean => {
    let error
    if (!v || !v.match(/[0-9]{9}/)) {
      error = 'errors.invalid.mobile'
    }
    setValidationError({ mobile: error })
    sendErrorDataLayer(t(error))
    return !error
  }, [])

  const validateIdentifier = useCallback((v: string): boolean => {
    let error
    if (!v) {
      error = 'errors.required'
    }
    if (!v.match(/[a-z]?[0-9]{8}[a-z]?/)) {
      error = 'errors.invalid.idNumber'
    }
    setValidationError({ identifier: error })
    sendErrorDataLayer(t(error))
    return !error
  }, [])

  const validateFullName = useCallback((v: string): boolean => {
    let error
    if (!v) {
      error = 'errors.required'
    }
    setValidationError({ fullName: error })
    sendErrorDataLayer(t(error))
    return !error
  }, [])

  const validateEmail = useCallback((v: string): boolean => {
    let error
    if (!v) {
      error = 'errors.required'
    }
    setValidationError({ email: error })
    sendErrorDataLayer(t(error))
    return !error
  }, [])

  const validateLegalAccepted = useCallback((v: boolean): boolean => {
    let error
    if (!v) {
      error = 'errors.required'
    }
    setValidationError({ legal: error })
    sendErrorDataLayer(t(error))
    return !error
  }, [])

  const validateCommonFields = useCallback((): boolean => {
    const isValidBirthDates = birthDates.every((birthDate, index) =>
      validateBirthDate(birthDate, index === firstInsuredIndex ? firstInsuredMinAge : otherInsuredMinAge, index),
    )

    const isValidPostalCode = validatePostalCode(postalCode)

    return isValidBirthDates && isValidPostalCode
  }, [birthDates, postalCode, validateBirthDate, validatePostalCode])

  const validateTakeOutFields = useCallback((): boolean => {
    const isValidFullName = validateFullName(fullName)
    const isValidIdentifier = validateIdentifier(identifier)
    const isValidEmail = validateEmail(email)
    const isValidMobile = validateMobile(mobile)
    const isLegalAccepted = validateLegalAccepted(legalAccepted)

    return isValidFullName && isValidIdentifier && isValidEmail && isValidMobile && isLegalAccepted
  }, [
    fullName,
    identifier,
    email,
    mobile,
    legalAccepted,
    validateFullName,
    validateIdentifier,
    validateEmail,
    validateMobile,
    validateLegalAccepted,
  ])

  const callApi = useCallback(
    async (action: 'CalculateQuota' | 'TakeOut') => {
      if (action === 'CalculateQuota') {
        sendCalculateDataLayer()
      }

      setValidationError({})
      if (!validateCommonFields()) {
        return
      }

      if (action === 'TakeOut' && !validateTakeOutFields()) {
        return
      }

      setApiError('')
      const payload = {
        postalCode,
        policyHolders: birthDates.map((birthDate) => ({ birthDate: new Date(birthDate).toISOString() })),
        contracting: undefined,
      }
      if (action === 'TakeOut') {
        payload.contracting = {
          fullName,
          identifier,
          email,
          mobile,
        }
      }
      await lkWebApi
        .postInsuranceFuneralQuota(payload, action)
        .then(({ data }) => {
          if (!data.succeeded) {
            setResponse(null)
          }
          setResponse(data.data)
          setSentOk(action === 'TakeOut')
          if (action === 'CalculateQuota') {
            sendResultDataLayer({ cuota_seguro: data.data.total.amount.toString() })
          }
          if (action === 'TakeOut') {
            sendGenerateLeadDataLayer({
              cuota_seguro: data.data.total.amount.toString(),
              status: widgetDeceaseInsuranceEvents.status.ok,
              email,
              phone_number: mobile.replace(' ', ''),
              value: data.data.total.amount.toString(),
              currency: data.data.total.currency,
            })
          }
        })
        .catch((error) => {
          setResponse(null)
          const errorMessage = error?.message || error
          setApiError(errorMessage)
          if (action === 'TakeOut') {
            sendGenerateLeadDataLayer({
              status: widgetDeceaseInsuranceEvents.status.ko,
              email,
              phone_number: mobile.replace(' ', ''),
              value: response.total.amount.toString(),
              currency: response.total.currency,
            })
          }
          sendErrorDataLayer(errorMessage)
        })
    },
    [
      birthDates,
      email,
      fullName,
      identifier,
      legalAccepted,
      lkWebApi,
      mobile,
      postalCode,
      validateBirthDate,
      validateEmail,
      validateFullName,
      validateIdentifier,
      validateLegalAccepted,
      validateMobile,
      validatePostalCode,
    ],
  )

  const addInsured = () => {
    setBirthDates([...birthDates, ''])
  }

  const removeInsured = (indexToDelete) => {
    setBirthDates(birthDates.filter((_, index) => index !== indexToDelete))
  }

  useEffect(() => {
    setResponse(null)
    if (birthDates.length !== insured.length) {
      setInsured(birthDates)
    }
  }, [birthDates, insured.length])

  return (
    <div className="lk-calculator lk-decease-insurance" data-testid="lk-widget-comfort-accident-insurance">
      <div className="lk-calculator__container row">
        <div className="lk-calculator__main col-lg-8 col-md-12">
          <div className="lk-calculator__content">
            <div className="lk-calculator__intro">
              <h2 className="lk-calculator__title">{t('widgetDeceaseInsurance.title')}</h2>
            </div>
            <p className="strong">{t('widgetDeceaseInsurance.description')}</p>
            <form action="#" method="post" className="lk-calculator__options">
              {insured.map((item, index) => {
                const birthDateKey = `birthDate_${index}`
                const hasError = !!validationErrors && !!validationErrors[birthDateKey]
                return (
                  <div className="row" key={`insured-${item}-${birthDateKey}`}>
                    <div className="col-sm-3 col-xs-12 as-center strong">
                      {index + 1}º {t('calculators.insured')}
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <LocalizedDatePicker
                        label={t('calculators.birthDate')}
                        onChange={(date) => {
                          const newBirthDates = [...birthDates]
                          newBirthDates[index] = date?.toISOString().split('T')[0] ?? ''
                          setBirthDates(newBirthDates)
                          setResponse(null)
                        }}
                        maxDate={
                          new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() -
                                (index === firstInsuredIndex ? firstInsuredMinAge : otherInsuredMinAge),
                            ),
                          )
                        }
                        language={language}
                        selected={new Date(new Date().setFullYear(new Date().getFullYear() - maxAge + 20))}
                        error={hasError}
                        errorMessage={hasError ? t(validationErrors[birthDateKey]) : ''}
                        required
                      />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <Input
                        type="number"
                        name={`postalCode_${index}`}
                        label={t('calculators.postalCode')}
                        min="1000"
                        max="52999"
                        onChange={(e) => {
                          setPostalCode(e.target.value)
                          setResponse(null)
                        }}
                        value={postalCode}
                        error={!!validationErrors.postalCode}
                        errorMessage={t(validationErrors.postalCode)}
                        disabled={index > 0}
                        required
                      />
                    </div>
                    <div className="col-sm-1 col-xs-12">
                      <div className="lk-decease-insurance__row-actions">
                        {birthDates.length > 1 && (
                          <Button
                            type="button"
                            buttonRole="secondary"
                            className="lk-calculator__options-remove"
                            buttonType="compact"
                            onClick={() => removeInsured(index)}
                          >
                            <Icon name="trash" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className="row">
                <div className="col-sm-8 col-xs-12 actions">
                  <Button
                    type="button"
                    buttonRole="secondary"
                    disabled={!(birthDates.length < 5)}
                    label={t('widgetDeceaseInsurance.addMoreInsured')}
                    onClick={addInsured}
                  />
                  <Button
                    type="button"
                    buttonRole={response ? 'secondary' : 'primary'}
                    label={t('calculators.calculateButton')}
                    onClick={() => callApi('CalculateQuota')}
                  />
                </div>
              </div>
            </form>
            <div className="lk-simulator__footer">
              <div className="row">
                <div className="col-lg-12">{t('widgetDeceaseInsurance.legalInfo')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="lk-calculator__aside col-lg-4 col-md-12 bg-berenjena-claro-4">
          <div className="lk-calculator__content">
            <p className="lk-calculator__highlighted-text strong m-0">{t('calculators.annualAmount')}</p>
            <div className="lk-calculator__result">
              <p className="lk-calculator__quantity">
                <span className="strong" data-testid="lk-calculator-quantity">
                  {formatCurrency(response?.total?.amount)}
                </span>
                {t('calculators.currencyPerYear')}
              </p>
            </div>
            <div className="lk-calculator__key-points">
              <div className="lk-calculator__key-point">
                <p>{t('widgetDeceaseInsurance.mensualAmount')}</p>
                <p className="strong">{formatCurrency(response?.totalByMonth.amount)}</p>
              </div>
            </div>
            <div className="lk-decease-insurance__contact-form">
              <p>{t('widgetDeceaseInsurance.personalDataTitle')}</p>
              <Input
                type="text"
                name="fullName"
                label={t('widgetDeceaseInsurance.fullName')}
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
                error={!!validationErrors.fullName}
                errorMessage={t(validationErrors.fullName)}
                disabled={!response}
                required
              />
              <Input
                type="text"
                name="identifier"
                label={t('widgetDeceaseInsurance.identifier')}
                onChange={(e) => setIdentifier(e.target.value)}
                value={identifier}
                error={!!validationErrors.identifier}
                errorMessage={t(validationErrors.identifier)}
                disabled={!response}
                required
              />
              <Input
                type="email"
                name="email"
                label={t('widgetDeceaseInsurance.email')}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                error={!!validationErrors.email}
                errorMessage={t(validationErrors.email)}
                disabled={!response}
                required
              />
              <Input
                type="tel"
                name="mobile"
                label={t('widgetDeceaseInsurance.mobile')}
                onChange={(e) => setMobile(e.target.value)}
                value={mobile}
                error={!!validationErrors.mobile}
                errorMessage={t(validationErrors.mobile)}
                disabled={!response}
                required
              />
              {!sentOk && (
                <LegalCheckbox
                  fieldName="legal"
                  legalTitle={t('widgetDeceaseInsurance.legalLabel')}
                  legalLink={`/${legalLink?.slug?.current}`}
                  onLegalChange={() => setLegalAccepted(true)}
                />
              )}
              <Button
                buttonRole="primary"
                label={t('calculators.apply')}
                type="button"
                onClick={() => callApi('TakeOut')}
                disabled={!response || !legalAccepted}
              />
            </div>
          </div>
        </div>
      </div>
      <AccessibleModal
        heading=""
        active={sentOk}
        onClose={() => {
          setSentOk(false)
          clearData()
        }}
        fixed
      >
        <p>{t('widgetDeceaseInsurance.SentMessage')}</p>
      </AccessibleModal>
      <AccessibleModal
        heading=""
        active={!!apiError}
        onClose={() => {
          setApiError('')
        }}
        fixed
      >
        <p>{apiError}</p>
      </AccessibleModal>
    </div>
  )
}

export default WidgetDeceaseInsurance
