import './Information.scss'

import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../../hooks/useI18nUtils'
import { InsuranceAccidentQuotaModules } from '../../../../../services/lk-apis/types'

const confortOptionImages = {
  bicycle: '/Components/WidgetInsuranceCalculator/bici.png',
  motorcycle: '/Components/WidgetInsuranceCalculator/moto.png',
}

const confortAdvantages = ['decease', 'permanent', 'hospitalization', 'deceasePayment']
const confortOptions = ['bicycle', 'motorcycle']
export interface ConfortInformationProps {
  selectedModule: string
  availableModules: InsuranceAccidentQuotaModules[]
}

const ConfortInformation: FC<ConfortInformationProps> = ({ selectedModule, availableModules }) => {
  const { t } = useTranslation()
  const { capitalizeFirstLetter } = useI18nUtils()
  const chosenModule = selectedModule
    ? availableModules.find((module) => module.moduleCode === parseInt(selectedModule, 10))
    : undefined

  return (
    <div className="lk-calculator__aside col-lg-4 col-md-12 bg-berenjena-claro-4">
      <div className="advantages-box advantages-box__confort">
        <div className="text-large strong">
          <Trans i18nKey="widgetInsuranceCalculator.advantagesConfort.title" components={{ b: <b /> }} />
        </div>
        {confortAdvantages.map((advantage, index) => (
          <div key={`confort_${advantage}`} className="advantages-box__confort-cobertures">
            <img
              className="confort__icon"
              src={`/Components/WidgetInsuranceCalculator/${advantage}.png`}
              alt={advantage}
            />
            <div>
              {t(`widgetInsuranceCalculator.advantagesConfort.${advantage}`)}{' '}
              <b className="strong">
                {chosenModule?.cobertures.length <= index &&
                  !chosenModule?.cobertures[index]?.import &&
                  t(`widgetInsuranceCalculator.advantagesConfort.${advantage}Import`, '')}
                {chosenModule?.cobertures.length > index
                  ? capitalizeFirstLetter(chosenModule?.cobertures[index]?.import)
                  : ''}
              </b>
            </div>
          </div>
        ))}
        <p className="confort__text-upper text-large strong">
          {t(`widgetInsuranceCalculator.advantagesConfort.includes`)}
        </p>
        {confortOptions.map((advantage) => (
          <div key={`confort_${advantage}`} className="confort">
            <img className="confort__icon" src={confortOptionImages[advantage]} alt="" />
            <div className="confort__text">
              <p className="text-large">{t(`widgetInsuranceCalculator.advantagesConfort.${advantage}`)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ConfortInformation
