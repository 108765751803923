import './RiskLevel.scss'

import { BrandedIcon } from '@lk/lk-design-system'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useIsLoaded from '../../hooks/useIsLoaded'
import SanitySchema from '../../lib/types/sanity-schema'

export type RiskLevelProps = Omit<SanitySchema.RiskLevel, '_type'>

const RiskLevel: FC<RiskLevelProps> = ({ riskLevel, maxRiskLevel, riskType }) => {
  const { t } = useTranslation()
  const { isLoaded } = useIsLoaded()
  const [customLegend, setCustomLegend] = useState<string>(
    t(`riskLevel.${riskType}.customLegend`, t('riskLevel.commonLegend', '')),
  )
  const [description, setDescription] = useState<string>(t(`riskLevel.${riskType}.description`, ''))
  const [advice, setAdvice] = useState<string>(t(`riskLevel.${riskType}.advice`, ''))
  const [maxRisk, setMaxRisk] = useState<number | undefined>(maxRiskLevel || 6)

  const prefixes = {
    savingsAccount: {
      description: null,
      advice: null,
    },
    deposits: {
      description: null,
      advice: (
        <figure className="prefix" aria-label={t('riskLevel.adviceLabel')}>
          <BrandedIcon name="lock-on" size={13} aria-hidden />
          <BrandedIcon name="lock-on" size={13} aria-hidden />
        </figure>
      ),
    },
    savingsInsurance: {
      description: (
        <figure className="prefix" aria-label={t('riskLevel.adviceLabel')}>
          <BrandedIcon name="lock-on" size={13} aria-hidden />
          <BrandedIcon name="lock-on" size={13} aria-hidden />
        </figure>
      ),
      advice: null,
    },
    pensionPlan: {
      description: (
        <figure className="risk-level__prefix" aria-label={t('riskLevel.adviceLabel')}>
          <BrandedIcon name="lock-on" size={13} aria-hidden />
          <BrandedIcon name="lock-on" size={13} aria-hidden />
        </figure>
      ),
      advice: (
        <figure className="risk-level__prefix" aria-label={t('riskLevel.adviceLabel')}>
          <BrandedIcon name="lock-on" size={13} aria-hidden />
          <BrandedIcon name="lock-on" size={13} aria-hidden />
        </figure>
      ),
    },
  }

  useEffect(() => {
    setCustomLegend(t(`riskLevel.${riskType}.customLegend`, t('riskLevel.commonLegend', '')))
    setDescription(t(`riskLevel.${riskType}.description`, ''))
    setAdvice(t(`riskLevel.${riskType}.advice`, ''))
    setMaxRisk(maxRiskLevel || 6)
  }, [riskType, maxRiskLevel, t])

  return (
    <div className="risk-level">
      <div className="risk-level__description">
        <div className="risk-level__level">
          <span className="risk-level__level-number">{riskLevel}</span> / {maxRisk}
        </div>
        {customLegend}
        {description?.includes('*') || (advice?.includes('*') && <sup>*</sup>)}
      </div>
      <div className="risk-level__legal-description">
        {(!isLoaded || (riskType && description)) && (
          <div className="risk-level__description-item">
            {prefixes[riskType].description}
            {description}
          </div>
        )}
        {(!isLoaded || (riskType && advice)) && (
          <div className="risk-level__description-item">
            {prefixes[riskType].advice}
            {advice}
          </div>
        )}
      </div>
    </div>
  )
}

export default RiskLevel
