import { Button } from '@lk/lk-design-system'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  InsuranceAccidentQuotaModules,
  PatchInsuranceAccidentQuotaArgs,
  PatchInsuranceAccidentQuotaOptions,
} from '../../../../../services/lk-apis/types'
import AccessibleModal from '../../../../AccessibleModal/AccessibleModal'
import AccessibleSelect from '../../../../AccessibleSelect/AccessibleSelect'
import LocalizedDatePicker from '../../../../LocalizedDatePicker/LocalizedDatePicker'

export interface ConfortProps {
  previousBirthDate: Date
  modules: InsuranceAccidentQuotaModules[]
  onSubmit: (values: PatchInsuranceAccidentQuotaArgs, option: PatchInsuranceAccidentQuotaOptions) => void
  onModuleChange: (module: string) => void
  onDeceaseChange: (decease: boolean) => void
}

const InsuranceCalculatorSuperLK: FC<ConfortProps> = ({
  previousBirthDate,
  modules,
  onSubmit,
  onModuleChange,
  onDeceaseChange,
}) => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  const [showModal, setShowModal] = useState(false)

  const deceaseOptions = [
    {
      label: t('widgetInsuranceCalculator.withoutDecease'),
      value: 'false',
    },
    {
      label: t('widgetInsuranceCalculator.withDecease'),
      value: 'true',
    },
  ]

  const [selectedModule, setSelectedModule] = useState()
  const [includeDecease, setIncludeDecease] = useState()

  const handleModuleChange = useCallback(
    (value) => {
      if (value !== selectedModule) {
        setSelectedModule(value)
        onModuleChange(value)
      }
    },
    [selectedModule],
  )

  const handleDeceaseChange = useCallback(
    (value) => {
      if (value !== includeDecease) {
        setIncludeDecease(value)
        onDeceaseChange(value === 'true')
      }
    },
    [includeDecease],
  )

  const handleSubmit = useCallback(() => {
    if (!selectedModule) return
    onSubmit(
      {
        moduleCode: parseInt(selectedModule, 10),
        includeDecease: includeDecease === 'true',
      },
      'GenerateBudgets',
    )
  }, [includeDecease, onSubmit, selectedModule])

  const moduleOptions = modules.map((module, index) => ({
    label: t('widgetInsuranceCalculator.option', { optionNumber: index + 1 }),
    value: module.moduleCode.toString(),
  }))

  return (
    <>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <LocalizedDatePicker
            language={language}
            label={t('widgetInsuranceCalculator.age')}
            onChange={() => undefined}
            selected={previousBirthDate}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <AccessibleSelect
            name="deceases"
            label={t('widgetInsuranceCalculator.deceases')}
            options={deceaseOptions}
            initialValue=""
            onItemSelected={handleDeceaseChange}
            placeholder={t('widgetInsuranceCalculator.selectDecease')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <AccessibleSelect
            name="modules"
            label={t('widgetInsuranceCalculator.quantity')}
            options={moduleOptions}
            initialValue=""
            onItemSelected={handleModuleChange}
            placeholder={t('widgetInsuranceCalculator.selectQuantity')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <Button
            icon="question-o"
            buttonRole="secondary"
            aria-label={t('widgetInsuranceCalculator.superLkHelp.modalLinkText')}
            className="lk-widget-insurance-calculator__help"
            size="sm"
            iconPos="right"
            onClick={() => setShowModal(true)}
            label={t('widgetInsuranceCalculator.superLkHelp.modalLinkText')}
          />
        </div>
      </div>

      <AccessibleModal
        active={showModal}
        heading={t('widgetInsuranceCalculator.superLkHelp.modalTitle')}
        fixed
        onClose={() => setShowModal(false)}
        size="large"
      >
        <iframe
          src={t('widgetInsuranceCalculator.superLkHelp.modalLink')}
          className="lk-legal-field__iframe"
          title="title"
        />
      </AccessibleModal>

      <div className="row">
        <div className="col-xs-12 lk-widget-insurance-calculator__actions">
          <Button
            buttonRole="primary"
            label={t('widgetInsuranceCalculator.offerButton')}
            type="button"
            onClick={handleSubmit}
            disabled={!includeDecease || !selectedModule}
          />
        </div>
      </div>
    </>
  )
}

export default InsuranceCalculatorSuperLK
