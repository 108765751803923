import { Button, Input } from '@lk/lk-design-system'
import addDays from 'date-fns/addDays'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { Country, Coverage, PostTravelProposalArgs } from '../../../../../services/lk-apis/types'
import AccessibleSelect from '../../../../AccessibleSelect/AccessibleSelect'
import LocalizedDatePicker from '../../../../LocalizedDatePicker/LocalizedDatePicker'

export interface InitialFormProps {
  countries: Country[]
  coverages: Coverage[]
  onSubmit: (values: PostTravelProposalArgs) => void
  initialValues?: PostTravelProposalArgs
  sendErrorDataLayer?: (errorMessage: string) => void
}

const TravelInsuranceInitialForm: FC<InitialFormProps> = ({
  countries,
  coverages,
  initialValues,
  onSubmit,
  sendErrorDataLayer,
}) => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  const [data, updateData] = useReducer((prev, newData) => ({ ...prev, ...newData }), { ...initialValues })
  const [errors, updateErrors] = useReducer(
    (prev: Record<string, string>, newData: Partial<Record<string, string>>) => ({ ...prev, ...newData }),
    {},
  )
  const countryOptions = countries?.map((country) => ({ label: country.name, value: country.code }))
  const coverageOptions = coverages?.map((coverage) => ({ label: coverage.name, value: coverage.id }))

  const handleCoverageChange = useCallback(
    (value) => {
      if (value !== data.coverageCode) {
        updateData({ coverageCode: value })
      }
    },
    [data],
  )

  const handleCountryChange = useCallback(
    (value) => {
      if (value !== data.countryCode) {
        updateData({ countryCode: value })
      }
    },
    [data],
  )

  const validateForm = useCallback(() => {
    const travelDuration = Math.floor(
      ((data.endDate?.getTime() || 0) - (data.startDate?.getTime() || 0)) / (1000 * 60 * 60 * 24),
    )
    const today = new Date().setHours(0, 0, 0, 0)

    const newErrors = {
      startDate:
        !data.startDate || data.startDate < today ? t('widgetTravelInsurance.errors.startDateError') : undefined,
      endDate:
        !data.endDate || data.endDate < data.startDate || travelDuration > 365
          ? t('widgetTravelInsurance.errors.endDateError')
          : undefined,
      countryCode: !data.countryCode ? t('widgetTravelInsurance.errors.countryCodeError') : undefined,
      coverageCode: !data.coverageCode ? t('widgetTravelInsurance.errors.coverageCodeError') : undefined,
      numberOfTravelers:
        data.travelerCount < 1 || data.travelerCount > 10
          ? t('widgetTravelInsurance.errors.numberOfTravelersError')
          : undefined,
    }
    updateErrors(newErrors)
    const hasErrors = Object.values(newErrors).some((error) => !!error)

    if (hasErrors) {
      const errorMessage = Object.entries(errors)
        .filter(([, value]) => !!value)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ')
      sendErrorDataLayer(errorMessage)
      return false
    }

    return true
  }, [data, t])

  return (
    <div className="lk-widget-travel__form">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <LocalizedDatePicker
            language={language}
            label={t('widgetTravelInsurance.departureDate')}
            onChange={(date) =>
              updateData({
                startDate: new Date(date.toISOString().replaceAll(/(T.*)/g, `T00:00:00Z`)),
              })
            }
            minDate={addDays(new Date(), 1)}
            error={!!errors.startDate}
            errorMessage={errors.startDate}
            startDate={data.startDate}
            endDate={data.endDate}
            required
          />
        </div>
        <div className="col-lg-6 col-md-12">
          <LocalizedDatePicker
            language={language}
            label={t('widgetTravelInsurance.returnDate')}
            onChange={(date) =>
              updateData({ endDate: new Date(date.toISOString().replaceAll(/(T.*)/g, `T00:00:00Z`)) })
            }
            minDate={data?.startDate ? addDays(data.startDate, 1) : addDays(new Date(), 1)}
            error={!!errors.endDate}
            errorMessage={errors.endDate}
            startDate={data.startDate}
            endDate={data.endDate}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <AccessibleSelect
            name="countryCode"
            label={t('widgetTravelInsurance.country')}
            options={countryOptions}
            onItemSelected={handleCountryChange}
            placeholder={t('widgetTravelInsurance.countryPlaceholder')}
            required
            initialValue={initialValues?.countryCode}
          />
        </div>
        <div className="col-lg-4 col-md-12">
          <Input
            type="number"
            label={t('widgetTravelInsurance.numberOfTravelers')}
            onChange={(e) => updateData({ travelerCount: parseInt(e.target.value, 10) })}
            required
            defaultValue={initialValues?.travelerCount}
            error={!!errors.numberOfTravelers}
            errorMessage={errors.numberOfTravelers}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <AccessibleSelect
            name="coverageCode"
            label={t('widgetTravelInsurance.cancellationCoverage')}
            placeholder={t('widgetTravelInsurance.cancellationCoveragePlaceholder')}
            options={coverageOptions}
            onItemSelected={handleCoverageChange}
            required
            initialValue={initialValues?.coverageCode}
          />
        </div>
      </div>
      <div className="lk-widget-travel__actions">
        <Button
          buttonType="button"
          onClick={() => validateForm() && onSubmit(data)}
          label={t('calculators.calculateButton')}
          disabled={!data.countryCode || !data.coverageCode || !data.startDate || !data.endDate || !data.travelerCount}
        />
      </div>
    </div>
  )
}

TravelInsuranceInitialForm.defaultProps = {
  initialValues: {
    startDate: null,
    endDate: null,
    countryCode: '',
    travelerCount: 1,
    coverageCode: '',
  },
  sendErrorDataLayer: undefined,
}

export default TravelInsuranceInitialForm
