import './WidgetCompanyCirculating.scss'

import { Button, Icon, Input, Popover } from '@lk/lk-design-system'
import classNames from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, FormEvent, useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../hooks/useI18nUtils'
import { SimpleBlockContent } from '../../../../lib/types/sanity-schema'
import LKApis from '../../../../services/lk-apis/LKApisProvider'
import { CirculatingSimulatorResult, PostCirculatingSimulatorArgs } from '../../../../services/lk-apis/types'
import LoadingIndicator from '../../../LoadingIndicator/LoadingIndicator'
import PortableText from '../../../PortableText/PortableText'
import BolApiErrorMessage from '../../common/BolApiErrorMessage'

export interface WidgetCompanyCirculatingProps {
  maximumAmount: number
  minimumAmount: number
  minimumDays: number
  maximumDays: number
  subtitleInsuranceCalculator: SimpleBlockContent
}

const WidgetCompanyCirculating: FC<WidgetCompanyCirculatingProps> = ({
  maximumAmount,
  minimumAmount,
  minimumDays,
  maximumDays,
  subtitleInsuranceCalculator,
}) => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  const { lkWebApi } = useContext(LKApis)
  const breakpoints = useBreakpoint()
  const { formatCurrency, formatInterest, formatDate, getPreviousBusinessDay } = useI18nUtils()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<CirculatingSimulatorResult>()
  const [errorCode, setErrorCode] = useState<string>(null)
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(true)
  const [amountPopover, setAmountPopover] = useState(false)
  const [values, setValues] = useState<PostCirculatingSimulatorArgs>({
    amount: { amount: 0, currency: 'EUR' },
    daysToPay: 0,
  })
  const [buttonsRoles, setButtonsRoles] = useState({
    calculateButton: 'primary',
    applyButton: 'secondary',
  })
  const applyButtonClassnames = classNames('lk-button lk-calculator__button lk-calculator__button--apply', {
    disabled: applyButtonDisabled,
    'lk-button--primary': buttonsRoles.applyButton === 'primary',
    'lk-button--secondary': buttonsRoles.applyButton === 'secondary',
  })

  const idPrefix = 'widget-company-circulating'

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setResponse(null)

    try {
      setLoading(true)
      const formResponse = await lkWebApi.postCirculatingSimulator(values)
      setResponse(formResponse.data.data)
      setApplyButtonDisabled(false)
      setButtonsRoles({
        calculateButton: 'secondary',
        applyButton: 'primary',
      })
      setErrorCode(null)
      setLoading(false)
    } catch (error) {
      setApplyButtonDisabled(true)
      setErrorCode(error?.response?.data?.errors?.detail || 'UNKNOWN_ERROR')
      setLoading(false)
    }
  }

  return (
    <>
      <BolApiErrorMessage errorCode={errorCode} onClose={() => setErrorCode(null)} />
      <form
        onSubmit={handleSubmit}
        className="lk-calculator"
        aria-label="form"
        data-testid="lk-widget-company-circulating"
      >
        <div className="lk-calculator__container row">
          <div className="lk-calculator__main col-lg-8 col-md-12 p-0">
            <div className="lk-calculator__content">
              <div className="lk-calculator__intro">
                <h2 className="lk-calculator__title">{t('widgetCompanyCirculating.title')}</h2>
              </div>
              {subtitleInsuranceCalculator && (
                <div className="lk-calculator__intro">
                  {subtitleInsuranceCalculator && <PortableText blocks={subtitleInsuranceCalculator} />}
                </div>
              )}
              <p className="lk-calculator__highlighted-text strong">
                {t('widgetCompanyCirculating.setData')}
                <sup>*</sup>
              </p>
              <div className="lk-calculator__options">
                <div className="row">
                  <div className="col-sm-3 col-xs-12 as-center">
                    <p className="lk-calculator__options-desc strong">
                      <label htmlFor={`${idPrefix}-amount`}>{t('widgetCompanyCirculating.amount')}</label>
                      <Icon
                        name="question-o"
                        className="as-center"
                        onTouchStart={() => setAmountPopover(true)}
                        onMouseOver={() => setAmountPopover(true)}
                        onTouchEnd={() => setAmountPopover(false)}
                        onMouseLeave={() => setAmountPopover(false)}
                      />
                    </p>
                  </div>
                  <div className="col-sm-9 col-xs-12 d-flex">
                    {amountPopover === true ? <Popover body={t('widgetCompanyCirculating.amountPopover')} /> : null}
                    <Input
                      append="€"
                      id={`${idPrefix}-amount`}
                      name="amount"
                      type="number"
                      onInput={(e) => {
                        e.persist()
                        setValues((prev) => ({
                          ...prev,
                          amount: { amount: Number(e.target.value), currency: 'EUR' },
                        }))
                      }}
                      required
                      min={minimumAmount}
                      max={maximumAmount}
                      alt={t('widgetCompanyCirculating.amount')}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 col-xs-12 as-center">
                    <p className="lk-calculator__options-desc strong">
                      <label htmlFor={`${idPrefix}-term`}>{t('widgetCompanyCirculating.term')}</label>
                    </p>
                  </div>
                  <div className="col-sm-9 col-xs-12 d-flex">
                    <Input
                      append={t('widgetCompanyCirculating.days')}
                      id={`${idPrefix}-term`}
                      name="term"
                      type="number"
                      onInput={(e) => {
                        e.persist()
                        setValues((prev) => ({
                          ...prev,
                          daysToPay: Number(e.target.value),
                        }))
                      }}
                      required
                      min={minimumDays}
                      max={maximumDays}
                      alt={t('widgetCompanyCirculating.term')}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 col-xs-12" />
                  <div className="col-sm-9 col-xs-12 company-circulating__submit-button">
                    <Button
                      type="submit"
                      buttonRole={buttonsRoles.calculateButton as 'primary' | 'secondary'}
                      label={t('widgetCompanyCirculating.calculate')}
                      role="button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lk-calculator__aside col-lg-4 col-md-12 bg-berenjena-claro-4 p-0" data-testid="loan-proposal">
            <div className="lk-calculator__content">
              <LoadingIndicator loading={loading} />
              <p className="lk-calculator__highlighted-text strong m-0">{t('widgetCompanyCirculating.totalLoan')}</p>
              <div className="lk-calculator__button-block">
                <div className="lk-calculator__result">
                  <p className="lk-calculator__quantity">
                    <span className="strong">{formatCurrency(response?.totalAmount?.amount)}</span>
                  </p>
                </div>
                {breakpoints.sm && (
                  <a href={response ? response.url : '#'} className={applyButtonClassnames}>
                    {t('widgetCompanyCirculating.personalizedResearch')}
                  </a>
                )}
              </div>
              <p className="m-0">
                <sup>*</sup>
                <sup>*</sup>
                <span className="text-large tin">
                  {t('widgetCompanyCirculating.tin')}: {formatInterest(response?.tin, 3)}
                </span>
                <span className="strong text-large">
                  {t('widgetCompanyCirculating.tae')}: {formatInterest(response?.tae, 3)}
                </span>
              </p>
              <div className="lk-calculator__key-points">
                <div className="lk-calculator__key-point">
                  <p>{t('widgetCompanyCirculating.financedAmount')}</p>
                  <p className="strong">{formatCurrency(response?.amount?.amount)}</p>
                </div>
                <div className="lk-calculator__key-point">
                  <p>{t('widgetCompanyCirculating.bankInterest')}</p>
                  <p className="strong">{formatCurrency(response?.interestAmount?.amount)}</p>
                </div>
              </div>
              <div className="lk-calculator__key-points interest-composition">
                <div className="lk-calculator__key-point lk-calculator__key-point--no-border">
                  <p>
                    <sup>*</sup>
                    <sup>*</sup>
                    {t('widgetCompanyCirculating.tinComposition')}
                  </p>
                </div>
                <div className="lk-calculator__key-point lk-calculator__key-point--no-border">
                  <p>{t('widgetCompanyCirculating.euribor')}</p>
                  <p className="strong">{formatInterest(response?.interestComposition.euribor, 3)}</p>
                </div>
                <div className="lk-calculator__key-point lk-calculator__key-point--no-border">
                  <p>{t('widgetCompanyCirculating.datePublished')}</p>
                  <p className="strong">
                    {response?.interestComposition
                      ? formatDate(getPreviousBusinessDay(new Date()).toString(), language)
                      : '-'}
                  </p>
                </div>
                <div className="lk-calculator__key-point lk-calculator__key-point--no-border">
                  <p>{t('widgetCompanyCirculating.differential')}</p>
                  <p className="strong">{formatInterest(response?.interestComposition.differential)}</p>
                </div>
                <div className="lk-calculator__message">
                  <p>{t('widgetCompanyCirculating.personalizedMessage')}</p>
                </div>
              </div>
              {!breakpoints.sm && (
                <a href={response ? response.url : '#'} className={applyButtonClassnames}>
                  {t('widgetCompanyCirculating.personalizedResearch')}
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="lk-calculator__footer">
          <div className="row">
            <div className="col-lg-12 text-small">
              <Trans i18nKey="widgetCompanyCirculating.legal" components={{ sup: <sup /> }} />
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default WidgetCompanyCirculating
