import { Button, Input } from '@lk/lk-design-system'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { ChangeEvent, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Cvv2Icon from '../../../../../images/Widgets/cvv2.png'
import AccessibleModal from '../../../../AccessibleModal/AccessibleModal'
import LegalCheckbox from '../../../../LegalCheckbox/LegalCheckbox'
import useBolUtils from './useBolUtils'

const legalLinks = {
  es: '/es/lopd/banca-online/',
  eu: '/eu/lopd/online-banka/',
}

export type FormFields =
  | 'identifier'
  | 'otp'
  | 'name'
  | 'surname'
  | 'email'
  | 'mobile'
  | 'prefix'
  | 'city'
  | 'address'
  | 'postalCode'
  | 'cardNumber'
  | 'cvv2'
  | 'expirationDate'

interface WidgetBolFormRecoverProps {
  identifier?: string
  fields: FormFields[]
  onFormSubmit: (data: Partial<Record<FormFields, string>>) => void
  submitLabel: string
  showLegalCheckbox: boolean
}

type FormData = Partial<Record<FormFields, string>>

type FieldErrors = Record<FormFields, boolean>

const emptyData: FormData = {
  identifier: '',
  otp: '',
  name: '',
  surname: '',
  email: '',
  mobile: '',
  prefix: '',
  city: '',
  address: '',
  postalCode: '',
  cardNumber: '',
  cvv2: '',
  expirationDate: '',
}

const BaseForm: React.FC<WidgetBolFormRecoverProps> = ({
  identifier,
  fields,
  onFormSubmit,
  submitLabel,
  showLegalCheckbox,
}) => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  const [readyToSend, setReadyToSend] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [data, setData] = useReducer(
    (state: FormData, newData: Partial<FormData>): FormData => (!newData ? state : { ...state, ...newData }),
    { ...emptyData, identifier },
  )
  const [visibleFields, setVisibleFields] = useState<Record<FormFields, boolean>>(null)
  const [validationErrors, setValidationError] = useReducer(
    (state: FieldErrors, error: Partial<FieldErrors>): Partial<FieldErrors> => (!error ? {} : { ...state, ...error }),
    {},
  )
  const [legalAccepted, setLegalAccepted] = useState(false)
  const { isValidField, getFieldFormatter, getFieldNormalizer, splitMobile } = useBolUtils()
  const [showCvv2Help, setShowCvv2Help] = useState(false)
  const ccExpirationRef = useRef(null)

  useEffect(() => {
    let formFilled = true
    fields.forEach((key) => {
      formFilled = formFilled && !!data[key] && !validationErrors[key]
    })
    if (showLegalCheckbox) {
      setReadyToSend(formFilled && legalAccepted)
    } else {
      setReadyToSend(formFilled)
    }
  }, [data, fields, legalAccepted, showLegalCheckbox, validationErrors])

  useEffect(() => {
    let newVisibleState: Record<FormFields, boolean> = null
    fields.forEach((field) => {
      newVisibleState = { ...newVisibleState, [field]: fields.includes(field) }
    })
    setReadyToSend(false)
    setVisibleFields(newVisibleState)
    if (identifier) {
      setData({ identifier })
    }
    setValidationError(null)
  }, [fields, identifier])

  const handleSubmit = useCallback(async () => {
    setIsSending(true)
    try {
      const callBackData: Partial<FormData> = { identifier }
      fields.forEach((f) => {
        const normalize = getFieldNormalizer(f)
        if (f === 'mobile') {
          const { prefix, mobile } = splitMobile(data[f])
          callBackData.mobile = normalize(mobile)
          callBackData.prefix = prefix
        } else {
          callBackData[f] = normalize(data[f])
        }
      })
      await onFormSubmit(callBackData)
    } finally {
      setIsSending(false)
    }
  }, [data, fields, getFieldNormalizer, identifier, onFormSubmit, splitMobile])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      const isOk = isValidField(name, value)
      setValidationError({ [name]: !isOk })
      const formatField = getFieldFormatter(name)
      setData({ [name]: formatField(value) })
    },
    [getFieldFormatter, isValidField],
  )

  const handleCcExpirationBlur = useCallback(() => {
    const name = 'expirationDate'
    const { value } = ccExpirationRef.current
    const isOk = isValidField(name, value)
    setValidationError({ [name]: !isOk })
    setData({ [name]: value })
  }, [isValidField])

  return (
    <div data-testid="lk-widget-bol-new-user">
      {(visibleFields?.identifier || identifier) && (
        <div className="row">
          <div className="col small-input col-lg-2 col-md-6 col-sm-12">
            <Input
              name="identifier"
              label={t('widgetBolNewUser.identifier')}
              type="text"
              defaultValue={identifier}
              onChange={handleChange}
              disabled={!visibleFields?.identifier}
              error={validationErrors?.identifier}
              errorMessage={t('errors.invalid.identifier')}
              required
              maxLength={10}
              minLength={6}
            />
          </div>
        </div>
      )}
      {visibleFields?.otp && (
        <>
          <div className="row">
            <div className="col small-input col-lg-2 col-md-6 col-sm-12">
              <Input
                name="otp"
                label={t('widgetBolNewUser.otp')}
                type="text"
                onChange={handleChange}
                error={validationErrors?.otp}
                errorMessage={t('errors.invalid.otp')}
                maxLength={6}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-12 col-md-6 col-sm-12 tip">{t('widgetBolNewUser.otpTip')}</div>
          </div>
        </>
      )}
      {(visibleFields?.name || visibleFields?.surname) && (
        <div className="row">
          <div className="col small-input col-lg-2 col-md-12 col-sm-12">
            <Input
              name="name"
              label={t('widgetBolNewUser.name')}
              type="text"
              onChange={handleChange}
              error={validationErrors?.name}
              errorMessage={t('errors.invalid.name')}
              required
            />
          </div>
          <div className="col col-lg-3 col-md-6 col-sm-12">
            <Input
              id="widgetBolNewUserSurname"
              name="surname"
              label={t('widgetBolNewUser.surname')}
              type="text"
              onChange={handleChange}
              error={validationErrors?.surname}
              errorMessage={t('errors.invalid.surname')}
              required
            />
          </div>
        </div>
      )}
      <div className="row">
        {visibleFields?.email && (
          <div className="col col-lg-3 col-md-12 col-sm-12 email">
            <Input
              label={t('widgetBolNewUser.email')}
              name="email"
              type="email"
              onChange={handleChange}
              error={validationErrors?.email}
              errorMessage={t('errors.invalid.email')}
              required
            />
          </div>
        )}
        {visibleFields?.mobile && (
          <div className="col small-input col-lg-2 col-md-8 col-sm-12 d-flex">
            <div className="lk-input">
              <div className="lk-input__label lk-label lk-label--disabled lk-input__label ">&nbsp;</div>
              <div className="lk-input__box lk-input__box--disabled">
                <div className="lk-input__field mobile-prefix">+34</div>
              </div>
            </div>
            <Input
              name="mobile"
              label={t('widgetBolNewUser.mobile')}
              type="tel"
              value={data.mobile}
              onChange={handleChange}
              error={validationErrors?.mobile}
              errorMessage={t('errors.invalid.mobile')}
              maxLength={11}
              required
            />
          </div>
        )}
      </div>
      {visibleFields?.address && (
        <div className="row">
          <div className="col large-input col-lg-5 col-md-8 col-sm-12">
            <Input
              name="address"
              label={t('widgetBolNewUser.address')}
              onChange={handleChange}
              error={validationErrors?.address}
              errorMessage={t('errors.invalid.address')}
              required
            />
          </div>
        </div>
      )}
      {(visibleFields?.city || visibleFields?.postalCode) && (
        <div className="row">
          {visibleFields?.city && (
            <div className="col col-lg-3 col-md-8 col-sm-12">
              <Input
                name="city"
                label={t('widgetBolNewUser.city')}
                onChange={handleChange}
                error={validationErrors?.city}
                errorMessage={t('errors.invalid.city')}
                required
              />
            </div>
          )}
          {visibleFields?.postalCode && (
            <div className="col small-input col-lg-2 col-md-8 col-sm-12">
              <Input
                name="postalCode"
                label={t('widgetBolNewUser.postalCode')}
                onChange={handleChange}
                error={validationErrors?.postalCode}
                errorMessage={t('errors.invalid.postalCode')}
                required
              />
            </div>
          )}
        </div>
      )}
      {(visibleFields?.cardNumber || visibleFields?.cvv2 || visibleFields?.expirationDate) && (
        <div className="row">
          <div className="col col-sm-12 lk-bol-new-user__card-text">{t('widgetBolNewUser.cardIntro')}</div>
        </div>
      )}
      {(visibleFields?.cardNumber || visibleFields?.cvv2 || visibleFields?.expirationDate) && (
        <div className="lk-bol-new-user__card-box bg-berenjena-claro-4">
          <div className="row">
            {visibleFields?.cardNumber && (
              <div className="col large-input col-lg-5 col-md-12 col-sm-12">
                <Input
                  name="cardNumber"
                  type="text"
                  label={t('widgetBolNewUser.cardNumber')}
                  onInput={handleChange}
                  value={data.cardNumber}
                  error={validationErrors?.cardNumber}
                  errorMessage={t('errors.invalid.cardNumber')}
                  required
                  maxLength={19}
                />
              </div>
            )}
          </div>
          <div className="row">
            {visibleFields?.cvv2 && (
              <div className="col small-input col-lg-2 col-md-6 col-sm-6 col-7 cvv2">
                <Input
                  name="cvv2"
                  label={t('widgetBolNewUser.cvv2')}
                  type="text"
                  onInput={handleChange}
                  value={data.cvv2}
                  error={validationErrors?.cvv2}
                  errorMessage={t('errors.invalid.cvv2')}
                  required
                  maxLength={3}
                />
                <div className="cvv2__explanation">
                  <Button
                    icon="question-o"
                    buttonType="compact"
                    buttonRole="secondary"
                    aria-label={t('widgetBolNewUser.cvv2HeadingModal')}
                    size="sm"
                    onClick={() => setShowCvv2Help(true)}
                  />
                </div>
              </div>
            )}
            {visibleFields?.expirationDate && (
              <div className="col col-lg-3 col-md-6 col-sm-6 col-6 cvv2 cvv2__expiration">
                <Input
                  name="expirationDate"
                  type="cc-expiration"
                  inputMode="numeric"
                  label={t('widgetBolNewUser.expirationDate')}
                  error={validationErrors?.expirationDate}
                  errorMessage={t('errors.invalid.expirationDate')}
                  required
                  placeholder="mm/aa"
                  maxLength={5}
                  forwardRef={ccExpirationRef}
                  onBlur={handleCcExpirationBlur}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <AccessibleModal
        active={showCvv2Help}
        heading={t('widgetBolNewUser.cvv2HeadingModal')}
        fixed
        onClose={() => setShowCvv2Help(false)}
        size="medium"
      >
        <div className="cvv2-help">
          <img src={Cvv2Icon} alt={t('widgetBolNewUser.cvv2HeadingModal')} aria-hidden />
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('widgetBolNewUser.cvv2BodyModal') }}
          />
        </div>
      </AccessibleModal>

      {showLegalCheckbox && (
        <div className="row">
          <div className="col col-lg-12 col-md-12 col-sm-12 lk-bol-new-user__legal-checkbox">
            <LegalCheckbox
              fieldName="legal"
              legalTitle={t('widgetBolNewUser.legalLabel')}
              legalLink={legalLinks[language] || legalLinks.es}
              onLegalChange={() => setLegalAccepted(true)}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col col-lg-12 col-md-12 col-sm-12 d-flex lk-bol-new-user__submit-button">
          <Button
            disabled={!readyToSend || isSending}
            label={submitLabel}
            className="lk-button--web"
            type="button"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

BaseForm.defaultProps = {
  identifier: '',
}

export default BaseForm
