import 'react-pdf/dist/cjs/Page/AnnotationLayer.css'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import './LegalCheckbox.scss'

import { Button, Checkbox, Modal } from '@lk/lk-design-system'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page } from 'react-pdf'

import useLkUrlUtils from '../../hooks/useLkUrlUtils'
import { setupPdfWorker } from './pdfWorker'

export interface LegalCheckboxProps {
  fieldName: string
  legalTitle: string
  legalLink: string
  onLegalChange: (name: string, legalAccepted: boolean) => void
  docType?: string
  disabled?: boolean
}

let pdfWorkerLoaded = false
try {
  setupPdfWorker()
  pdfWorkerLoaded = true
} catch (e) {
  console.error('Failed to setup pdf worker', e)
}

const LegalCheckbox: FC<LegalCheckboxProps> = ({
  fieldName,
  legalTitle,
  legalLink,
  onLegalChange,
  docType,
  disabled,
}) => {
  const { t } = useTranslation()
  const [labelId, setLabelId] = useState<string>('legal_label')
  const [legalModal, setLegalModal] = useState(false)
  const [legalAccepted, setLegalAccepted] = useState(false)
  const { urlWithoutHeaderAndFooter } = useLkUrlUtils()
  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pdfWidth, setPdfWidth] = useState(800)
  const pdfContainerRef = React.useRef<HTMLDivElement>(null)
  const [allRead, setAllRead] = useState(false)
  const [isPdf, setIsPdf] = useState(false)
  const firstButtonRef = useRef<HTMLButtonElement>(null)
  const lastButtonRef = useRef<HTMLButtonElement>(null)

  const showModal = useCallback(
    (e) => {
      if (!disabled) {
        e.preventDefault()
        setLegalModal(true)
      }
    },
    [disabled],
  )

  const acceptLegal = useCallback(() => {
    onLegalChange(fieldName, true)
    setLegalAccepted(true)
    setLegalModal(false)
  }, [fieldName, onLegalChange])

  const declineLegal = useCallback(() => {
    onLegalChange(fieldName, false)
    setLegalAccepted(false)
    setLegalModal(false)
  }, [fieldName, onLegalChange])

  const onDocumentLoadSuccess = useCallback(({ numPages: docNumPages }) => {
    setNumPages(docNumPages)
    setPageNumber(1)
    setAllRead(docNumPages === 1)
  }, [])

  const onDocumentLoadError = useCallback(() => {
    setAllRead(false)
  }, [])

  useEffect(() => {
    if (pdfContainerRef.current) {
      setPdfWidth(pdfContainerRef.current.offsetWidth)
    }
  }, [pdfContainerRef.current])

  useEffect(() => {
    if (!isPdf || pageNumber === numPages) {
      setAllRead(true)
    }
  }, [isPdf, numPages, pageNumber])

  useEffect(() => {
    setLabelId(`${fieldName.replace(/([^a-z]+)/, '')}_label`)
  }, [fieldName])

  useEffect(() => {
    if (pdfWorkerLoaded && (legalLink?.includes('.pdf') || docType === 'pdf')) {
      setIsPdf(true)
      setAllRead(false)
    } else {
      setIsPdf(false)
      setAllRead(true)
    }
  }, [legalLink, docType])

  return (
    <>
      <span className="d-flex">
        <Checkbox
          name={fieldName}
          checked={legalAccepted}
          required
          disabled={disabled}
          onClick={(e) => showModal(e)}
          ariaLabelledBy={`${labelId}`}
        />
        <span
          className="lk-legal-field lk-link"
          onClick={showModal}
          onKeyUp={showModal}
          role="link"
          tabIndex={0}
          id={`${labelId}`}
        >
          {legalTitle || null}
        </span>
      </span>
      <Modal
        active={legalModal}
        onClose={declineLegal}
        className="lk-legal-field__modal"
        heading=""
        size="medium"
        fixed
      >
        {!isPdf && (
          <iframe
            src={urlWithoutHeaderAndFooter(legalLink)}
            className="lk-legal-field__iframe"
            title={legalTitle || null}
            role="alert"
            frameBorder={0}
            loading="lazy"
          />
        )}
        {isPdf && (
          <div className="lk-legal-field__modal-pdf" ref={pdfContainerRef}>
            <Document
              file={legalLink}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              error={t('loadPdf.error', '') || undefined}
            >
              <Page key={`page_${pageNumber}`} pageNumber={pageNumber} width={pdfWidth} />
            </Document>
          </div>
        )}
        <div className="lk-legal-field__footer">
          {isPdf && numPages > 1 && (
            <div>
              <Button
                type="button"
                label={t('customForm.legal.prev')}
                size="sm"
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
                forwardRef={pageNumber !== 1 ? firstButtonRef : null}
              />
              {pageNumber} / {numPages}
              <Button
                type="button"
                label={t('customForm.legal.next')}
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber > numPages - 1}
                forwardRef={pageNumber <= numPages - 1 ? firstButtonRef : null}
              />
            </div>
          )}
          <div>
            <Button
              type="button"
              label={t('customForm.legal.accept')}
              onClick={acceptLegal}
              size="lg"
              buttonRole="primary"
              className="lk-modal__button"
              disabled={!allRead}
              forwardRef={allRead ? firstButtonRef : null}
            />
            <Button
              type="button"
              onBlur={() => firstButtonRef.current?.focus()}
              label={t('customForm.legal.decline')}
              onClick={declineLegal}
              size="lg"
              buttonRole="secondary"
              className="lk-modal__button"
              forwardRef={lastButtonRef}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

LegalCheckbox.defaultProps = {
  docType: '',
  disabled: false,
}

export default LegalCheckbox
