import './Information.scss'

import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../../hooks/useI18nUtils'
import { InsuranceAccidentQuotaModules } from '../../../../../services/lk-apis/types'

const images = {
  boneFracture: '/Components/WidgetInsuranceCalculator/permanent.png',
  hospitalization: '/Components/WidgetInsuranceCalculator/hospitalization.png',
  dailyAsistance: '/Components/WidgetInsuranceCalculator/calendario-resize.png',
  deceases: '/Components/WidgetInsuranceCalculator/decease.png',
}

export interface SuperLKInformationProps {
  selectedModule: string
  availableModules: InsuranceAccidentQuotaModules[]
  showDecease: boolean
}

const SuperLKInformation: FC<SuperLKInformationProps> = ({ selectedModule, availableModules, showDecease }) => {
  const { t } = useTranslation()
  const { capitalizeFirstLetter } = useI18nUtils()
  const chosenModuleIndex = availableModules.findIndex((module) => module.moduleCode === parseInt(selectedModule, 10))
  const chosenModule = chosenModuleIndex > -1 ? availableModules[chosenModuleIndex] : undefined
  const options = ['boneFracture', 'hospitalization', 'dailyAsistance', ...(showDecease ? ['deceases'] : [])]

  const getCobertureImport = (index: number): string => {
    if (!chosenModule?.cobertures) return ''

    if (index < 3) {
      return capitalizeFirstLetter(chosenModule.cobertures[index]?.import || '')
    }

    if (index === 3) {
      const fallecimientoCoberture = chosenModule.cobertures.find(
        (coberture) => coberture.description.toLowerCase() === t('widgetSuperLkAccidents.decease').toLowerCase(),
      )
      return capitalizeFirstLetter(fallecimientoCoberture?.import || '')
    }

    return ''
  }

  return (
    <div className="lk-calculator__aside col-lg-4 col-md-12 bg-berenjena-claro-4">
      <div className="advantages-box advantages-box__superLK">
        <div className="advantages-box__superLK-title strong">
          <Trans
            className="strong"
            i18nKey="widgetInsuranceCalculator.advantagesSuperLk.title"
            components={{ b: <b /> }}
          />
        </div>
        {chosenModule && (
          <p className="text-large">
            <b>{t('widgetInsuranceCalculator.optionLong', { optionNumber: chosenModuleIndex + 1 })}</b>
          </p>
        )}
        {options.map((advantage, index) => (
          <div key={advantage} className="superLKAdvantages">
            <img className="superLKAdvantages__icon" src={images[advantage]} alt="" />
            <div className="superLKAdvantages__text">
              <p className="superLKAdvantages__text-upper text-large">
                <b>{t(`widgetInsuranceCalculator.advantagesSuperLk.${advantage}`)}</b>
                <strong>{` ${getCobertureImport(index)}`}</strong>
              </p>
              <p className="superLKAdvantages__text-under ">
                {t(`widgetInsuranceCalculator.advantagesSuperLk.${advantage}Subtitle`, '')}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SuperLKInformation
