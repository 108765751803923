import { Cta, DecisionTreeBranch, DecisionTreeLeave, SanityKeyed } from '../../lib/types/sanity-schema'
import { isSameTargetUrl } from '../../lib/urlUtils'

export const findNodeInPath = (
  key: string,
  tree: Array<SanityKeyed<DecisionTreeBranch | DecisionTreeLeave>>,
): SanityKeyed<DecisionTreeBranch | DecisionTreeLeave> => {
  if (!tree || !key) {
    return null
  }
  const node = tree.find((n) => n._key === key)
  if (node) {
    return node
  }

  for (let i = 0; i < tree.length; i += 1) {
    const childNode = findNodeInPath(key, tree[i].nodes)
    if (childNode) {
      return childNode
    }
  }
  return null
}

export const findParentNode = (
  key: string,
  tree: Array<SanityKeyed<DecisionTreeBranch>>,
): SanityKeyed<DecisionTreeBranch> => {
  if (!tree || !key) {
    return null
  }

  const parent = tree.find((node) => node?.nodes?.find((n) => n._key === key))
  if (parent) {
    return parent
  }

  for (let i = 0; i < tree.length; i += 1) {
    const parentNode = findParentNode(key, tree[i].nodes)
    if (parentNode) {
      return parentNode
    }
  }
  return null
}

const INDEX_SEPARATOR = '#'
const keysIndex = (
  prefix: string,
  path: SanityKeyed<DecisionTreeBranch>[] | SanityKeyed<DecisionTreeLeave>,
): string[] => {
  let result: string[] = []
  let newPrefix = prefix
  if (Array.isArray(path)) {
    path.forEach((node) => {
      result = [...result, ...keysIndex(newPrefix, node)]
    })
  } else {
    if (path?._key) {
      newPrefix = `${prefix}${prefix ? INDEX_SEPARATOR : ''}${path?._key || ''}`
      result = [...result, newPrefix]
    }
    if (path?.nodes) {
      result = [...result, ...keysIndex(newPrefix, path.nodes)]
    }
  }
  return result
}

export const calculateProgress = (
  key: string,
  nodes: Array<SanityKeyed<DecisionTreeBranch | DecisionTreeLeave>>,
): { total: number; actual: number } => {
  const index = keysIndex('', nodes)
  const actual = index.find((item) => item.endsWith(key)) || ''
  const posActual = actual.split(INDEX_SEPARATOR)?.length || 0
  const levelsOfNodesWithTheKey = index
    .filter((item) => (key ? item.includes(key) : true))
    .map((i) => i.split(INDEX_SEPARATOR)?.length || 0)
  const totalSteps = Math.max(...levelsOfNodesWithTheKey)
  return {
    total: totalSteps > 0 ? totalSteps - 1 : 0,
    actual: key ? posActual : 0,
  }
}

export interface TreeLeaveLink {
  _key: string
  group: string
  title: string
  cta: Cta
  active: boolean
  productInformation: string
}

export const getLeaveLinks = (
  key: string,
  tree: Array<SanityKeyed<DecisionTreeBranch | DecisionTreeLeave>>,
  parentIsActive = false,
): TreeLeaveLink[] => {
  let result: TreeLeaveLink[] = []

  tree.forEach((branch) => {
    const isActive = parentIsActive || !key || branch._key === key
    if (branch._type === 'decisionTreeLeave') {
      if (!result.find((item) => isSameTargetUrl(item.cta, branch.cta))) {
        result = [
          ...result,
          {
            _key: branch._key,
            title: branch.title,
            group: branch.group,
            cta: branch.cta,
            active: isActive,
            productInformation: branch.productInformation ? branch.productInformation : '',
          },
        ]
      }
    } else {
      result = [...result, ...getLeaveLinks(key, branch.nodes, isActive)]
    }
  })

  return result
}

export default {}
