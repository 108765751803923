import './Columns.scss'

import classNames from 'classnames'
import React, { FC, useContext, useEffect, useState } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'
import { AccordionBuilder } from '../Accordion/Accordion'
import Banner from '../Banner/Banner'
import { CardBuilder } from '../Card/Card'
import Comparative from '../Compararive/Comparative'
import ContentFilterContext from '../ContentFilter/ContentFilterProvider'
import DecisionTree from '../DecisionTree/DecisionTree'
import LkWidgetBuilder from '../LkWidget/LkWidgetBuilder'
import RiskLevel from '../RiskLevel/RiskLevel'
import { ShortcutBuilder } from '../Shortcut/Shortcut'
import Spacer from '../Spacer/Spacer'
import { TableWithStyleBuilder } from '../TableWithStyle/TableWithStyle'
import { TextWithIllustrationBuilder } from '../TextWithIllustration/TextWithIllustration'
import Video from '../Video/Video'

export type ColumnsProps = Omit<SanitySchema.Columns, '_type'>

const columnsStyles = {
  'one-column': 1,
  'two-columns': 2,
  'three-columns': 3,
  'four-columns': 4,
}

interface ColumnItemProps {
  numberOfColumns: string
  columnItem:
    | SanitySchema.SanityKeyed<SanitySchema.Shortcut>
    | SanitySchema.SanityKeyed<SanitySchema.Accordion>
    | SanitySchema.SanityKeyed<SanitySchema.Card>
    | SanitySchema.SanityKeyed<SanitySchema.TextWithIllustration>
    | SanitySchema.SanityKeyed<SanitySchema.LkWidget>
    | SanitySchema.SanityKeyed<SanitySchema.Video>
    | SanitySchema.SanityKeyed<SanitySchema.TableWithStyle>
    | SanitySchema.SanityKeyed<SanitySchema.DecisionTree>
    | SanitySchema.SanityKeyed<SanitySchema.Comparative>
    | SanitySchema.SanityKeyed<SanitySchema.Spacer>
    | SanitySchema.SanityKeyed<SanitySchema.RiskLevel>
    | SanitySchema.SanityKeyed<SanitySchema.Banner>
}

const ColumnItem: FC<ColumnItemProps> = ({ columnItem, numberOfColumns }) => {
  const { filterKeywords, isVisibleForFilterKeywords } = useContext(ContentFilterContext)
  const [hiddenByFilter, setHiddenByFilter] = useState(false)
  useEffect(() => {
    const filterableComponents = { shortcut: true, card: true }
    if (columnItem._type in filterableComponents) {
      setHiddenByFilter(!isVisibleForFilterKeywords(columnItem.keywords))
    }
  }, [isVisibleForFilterKeywords, filterKeywords, columnItem])

  const columnsSize = 12 / columnsStyles[numberOfColumns]
  const buildComponent = (sanityItem) => {
    const knownComponents = {
      accordion: AccordionBuilder,
      shortcut: ShortcutBuilder,
      card: CardBuilder,
      textWithIllustration: TextWithIllustrationBuilder,
      lkWidget: LkWidgetBuilder,
      video: Video,
      tableWithStyle: TableWithStyleBuilder,
      decisionTree: DecisionTree,
      comparative: Comparative,
      spacer: Spacer,
      riskLevel: RiskLevel,
      banner: Banner,
    }

    if (sanityItem._type in knownComponents) {
      const builder = knownComponents[sanityItem._type]
      return builder(sanityItem)
    }
    throw Error(`column component unknown: ${sanityItem._type}`)
  }

  const builtComponent = buildComponent(columnItem)

  return (
    <div
      key={`columnItem-${columnItem._key}`}
      className={classNames('lk-columns__item', `col-lg-${columnsSize}`, {
        'col-md-6 col-sm-12': columnsStyles[numberOfColumns] >= 2,
        'hidden-by-filter': hiddenByFilter,
        'hidden-empty-component': !builtComponent,
      })}
      data-testid="columns-item"
    >
      {builtComponent}
    </div>
  )
}

const Columns: FC<ColumnsProps> = ({ title, showTitle, renderAsH3, items, numberOfColumns }) => (
  <>
    {showTitle && title?.trim() && React.createElement(renderAsH3 ? 'h3' : 'div', { className: 'column-title' }, title)}
    <div className="row lk-columns" data-testid="columns-row">
      {items?.map((item) => (
        <ColumnItem columnItem={item} key={`column-item-${item._id || item._key}`} numberOfColumns={numberOfColumns} />
      ))}
    </div>
  </>
)

export default Columns
