import './Section.scss'

import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'

import * as SanitySchema from '../../lib/types/sanity-schema'
import Columns from '../Columns/Columns'

export type SectionProps = Omit<SanitySchema.Section, '_type'>

const Section: FC<SectionProps> = ({
  lanes,
  title,
  showTitle,
  backgroundColor,
  disabled,
  hideInMobile,
  hideInDesktop,
  showRightLane,
  rightLaneItems,
  hideRightLaneInMobile,
  scheduledEndDate,
  scheduledStartDate,
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const sectionClassnames = classNames('lk-section', {
    [`${backgroundColor}`]: backgroundColor !== undefined,
    'has-background-color': backgroundColor === 'bg-berenjena-claro-4',
    'hide-in-mobile': hideInMobile,
    'hide-in-desktop': hideInDesktop,
    'has-right-lane': showRightLane,
  })

  const rightLaneClassnames = classNames('container', 'rightLane', {
    'hide-in-mobile-right-lane': hideRightLaneInMobile,
  })

  const generateIdFromTitle = (titleToFormat: string) => {
    if (!titleToFormat || typeof titleToFormat !== 'string') return null
    return titleToFormat
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9\s]/gi, '')
      .replace(/\s+/g, '_')
  }

  useEffect(() => {
    const now = Date.now()
    const sectionStartDate = Date.parse(scheduledStartDate)
    const sectionEndDate = Date.parse(scheduledEndDate)
    if (disabled || now < sectionStartDate || now > sectionEndDate) {
      setIsVisible(false)
    }
  }, [disabled, scheduledEndDate, scheduledStartDate])

  return (
    <>
      {isVisible && (
        <section className={sectionClassnames}>
          <div className="container centralLane scroll-target" id={generateIdFromTitle(title)}>
            {title?.trim() && showTitle ? <h2 className="section-title">{title}</h2> : null}
            {lanes.map((item) => {
              switch (item._type) {
                case 'columns':
                  return (
                    <div className="lane" key={`lane-${item._key}`}>
                      <Columns
                        key={`column-${item._key}`}
                        title={item.title}
                        items={item.items}
                        showTitle={item.showTitle}
                        numberOfColumns={item.numberOfColumns}
                        renderAsH3={item.renderAsH3}
                      />
                    </div>
                  )
                default:
                  throw Error(`section lane component unknown: ${item._type}`)
              }
            })}
          </div>
          {showRightLane && (
            <aside className={rightLaneClassnames}>
              <Columns key="rightLane" items={rightLaneItems} numberOfColumns="one-column" />
            </aside>
          )}
        </section>
      )}
      {!isVisible && <div />}
    </>
  )
}
export default Section
