import { Button } from '@lk/lk-design-system'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import * as SanitySchema from '../../lib/types/sanity-schema'
import Figure from '../Figure/Figure'

export interface DecisionTreeBranchCardProps {
  node: SanitySchema.DecisionTreeBranch
  onSelected: () => void
}

const DecisionTreeBranchCard: FC<DecisionTreeBranchCardProps> = ({ node, onSelected }) => {
  const { t } = useTranslation()
  return (
    <div className="decisionTree-card" onClick={() => onSelected()} role="presentation">
      {node.shortcutImage && (
        <div className="decisionTree-card__header">
          <Figure node={node.shortcutImage.image as SanitySchema.MainImage} />
        </div>
      )}
      <div className="decisionTree-card__body">
        <div className="text text-large strong">{node.shortcutTitle}</div>
        <div className="text">{node.shortcutSubtitle}</div>
      </div>
      <div className="decisionTree-card__footer">
        <Button size="sm" label={t('decisionTree.select')} buttonRole="secondary" className="decisionTree-back" />
      </div>
    </div>
  )
}

export default DecisionTreeBranchCard
