import { Accordion, AccordionItem } from '@lk/lk-design-system'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../../hooks/useI18nUtils'
import { InsuranceAccidentQuotaModules, PatchInsuranceAccidentQuotaBudget } from '../../../../../services/lk-apis/types'
import InsuranceCalculatorOfferBox from './InsuranceCalculatorOfferBox'

interface InsuranceCalculatorOfferBoxProps {
  isConfort: boolean
  currentBudget: PatchInsuranceAccidentQuotaBudget
  onChangeBudget: (newSelectedBudget: InsuranceAccidentQuotaModules) => void
  selectedDeseace: boolean
}

const InsuranceCalculatorOffers: FC<InsuranceCalculatorOfferBoxProps> = ({
  isConfort,
  currentBudget,
  onChangeBudget,
  selectedDeseace,
}) => {
  const { formatCurrency, capitalizeFirstLetter } = useI18nUtils()
  const { t } = useTranslation()
  const [currentBudgetSelected, setCurrentBudgetSelected] = useState(true)
  const [superiorBudgetSelected, setSuperiorBudgetSelected] = useState(false)
  const [accordionStates, setAccordionStates] = useState<Record<string, boolean[]>>(() =>
    currentBudget?.budgetedModule?.cobertures
      ? {
          current: currentBudget.budgetedModule.cobertures.map(() => false),
          superior: currentBudget.superiorBudgetedModule?.cobertures?.map(() => false) || [],
        }
      : {},
  )

  const handleAccordionToggle = (budgetKey: string, index: number) => {
    setAccordionStates((prevStates) => ({
      ...prevStates,
      [budgetKey]: prevStates[budgetKey].map((state, i) => (i === index ? !state : state)),
    }))
  }
  const handleCurrentBudgetClick = () => {
    setCurrentBudgetSelected(true)
    setSuperiorBudgetSelected(false)
    onChangeBudget(currentBudget.budgetedModule)
  }

  const handleSuperiorBudgetClick = () => {
    setSuperiorBudgetSelected(true)
    setCurrentBudgetSelected(false)
    onChangeBudget(currentBudget.superiorBudgetedModule)
  }

  const budgets = [
    {
      key: 'current',
      title: t('widgetInsuranceCalculator.offerTitle'),
      selected: currentBudgetSelected,
      onClick: handleCurrentBudgetClick,
      amount: currentBudget.budgetedModule.anualQuota.amount,
      monthlyAmount: currentBudget.budgetedModule.anualQuota.amount / 12,
      currency: currentBudget.budgetedModule.anualQuota.currency,
      cobertures: currentBudget.budgetedModule.cobertures,
    },
  ]

  if (currentBudget?.superiorBudgetedModule) {
    budgets.push({
      key: 'superior',
      title: t('widgetInsuranceCalculator.improveYourInsurance'),
      selected: superiorBudgetSelected,
      onClick: handleSuperiorBudgetClick,
      amount: currentBudget.superiorBudgetedModule.anualQuota.amount,
      monthlyAmount: currentBudget.superiorBudgetedModule.anualQuota.amount / 12,
      currency: currentBudget.superiorBudgetedModule.anualQuota.currency,
      cobertures: currentBudget?.superiorBudgetedModule.cobertures,
    })
  }

  const coberturesTexts = isConfort
    ? []
    : [...(selectedDeseace ? ['decease'] : []), 'fracture', 'burn', 'hospitalization', 'home', 'daily']

  return (
    <div
      className={`lk-calculator__aside col-lg-${currentBudget?.superiorBudgetedModule ? '9' : '6'} col-md-12 col-sm-12`}
    >
      {budgets.map(({ key, selected, onClick, title, amount, monthlyAmount, cobertures }) => (
        <div
          key={key}
          className={`lk-widget-insurance-calculator__box col-lg-${
            currentBudget?.superiorBudgetedModule ? '4' : '6'
          } col-md-12`}
        >
          <InsuranceCalculatorOfferBox selected={selected} onClick={onClick} label={key}>
            <div className="lk-calculator__highlighted-text strong strong-magenta m-0 text-xlarge">{title}</div>
            <div className="lk-calculator__result">
              <p className="lk-calculator__quantity">
                <span className="strong" data-testid="lk-calculator-quantity">
                  {formatCurrency(amount)}
                  {t('calculators.currencyPerYear')}
                </span>
              </p>
            </div>
            <p className="text-large">
              <span className="strong" data-testid="lk-calculator-quantity">
                {formatCurrency(monthlyAmount)}
                {t('calculators.currencyPerMonth')}
              </span>
            </p>
            <div className="lk-calculator__key-points">
              <div className="includes strong ">{t('widgetInsuranceCalculator.includes')}</div>
              <div className="lk-calculator__key-points__list">
                {isConfort &&
                  cobertures?.map((property, index) => (
                    <div key={`insurance-calculator_${property.description}`} className="coberture-item">
                      <p className="text-large strong">
                        {`${capitalizeFirstLetter(property.description)} `}
                        <b>{capitalizeFirstLetter(property.import)}</b>
                      </p>
                      <p className="text-large">
                        {coberturesTexts[index] &&
                          t(`widgetInsuranceCalculator.offerDescriptions.${coberturesTexts[index]}`)}
                      </p>
                    </div>
                  ))}
                {!isConfort && (
                  <Accordion>
                    {cobertures?.map((property, index) => (
                      <AccordionItem
                        key={`insurance-calculator_${property.description}`}
                        title={
                          <>
                            <p className={`text-large lk-calculator__key-points__list-accordion-title `}>
                              {`${capitalizeFirstLetter(property.description)} `}
                            </p>
                            <b>{capitalizeFirstLetter(property.import)}</b>
                          </>
                        }
                        open={accordionStates[key]?.[index]}
                        onButtonClick={() => handleAccordionToggle(key, index)}
                      >
                        <p className="text-large">
                          {coberturesTexts[index] &&
                            t(`widgetInsuranceCalculator.offerDescriptions.${coberturesTexts[index]}`)}
                        </p>
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
              </div>
            </div>
          </InsuranceCalculatorOfferBox>
        </div>
      ))}
    </div>
  )
}

export default InsuranceCalculatorOffers
