import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LKApis from '../../../../../services/lk-apis/LKApisProvider'
import { PatchPinOption } from '../../../../../services/lk-apis/types'
import AccessibleModal from '../../../../AccessibleModal/AccessibleModal'
import BolApiErrorMessage from '../../../common/BolApiErrorMessage'
import LkCaptcha, { LkCaptchaRef } from '../../../common/LkCaptcha'
import WidgetBolBaseForm from '../common/BaseForm'
import HelpBox from '../common/HelpBox'
import { BolFormProps, BolStep } from '../common/types'
import useBolUtils from '../common/useBolUtils'
import FinishBetween14And17 from './FinishBetween14And17'

export interface OtpFormProps extends BolFormProps {
  petitionId: string
}

const OtpForm: FC<OtpFormProps> = ({ identifier, petitionId, onSuccess }) => {
  const { lkWebApi } = useContext(LKApis)
  const captchaRef = useRef<LkCaptchaRef>()
  const { t } = useTranslation()
  const { statusCodeToBolStep } = useBolUtils()
  const { language } = useContext(I18nextContext)
  const [showContactForm, setShowContactForm] = useState(false)
  const [errorCode, setErrorCode] = useState<string>(null)
  const [statusCode, setStatusCode] = useState<number>(null)

  const helpBoxes = [
    {
      key: 'noPhone',
      url: { es: '/es/oficinas-cajeros/', eu: '/eu/bulegoak-kutxazainak/' },
      target: '_blank',
      onClick: null,
    },
    { key: 'younger', url: null, onClick: () => setShowContactForm(true) },
    {
      key: 'noClient',
      url: {
        es: '/es/oficinas-cajeros/',
        eu: '/eu/bulegoak-kutxazainak/',
      },
      onClick: null,
    },
  ]

  const handleSubmit = useCallback(
    async (data) => {
      const { otp } = data
      try {
        const captchaToken = await captchaRef.current.getCaptchaToken()
        const response = await lkWebApi.customerManagement.patchPin(
          PatchPinOption.validateOtp,
          { otpCode: otp, petitionId },
          captchaToken,
        )
        const status = response?.status
        setStatusCode(status)
        if (status === 200) {
          const step = statusCodeToBolStep(response?.data?.data?.statusCode)
          onSuccess(step, { petitionId })
        }
        setErrorCode(null)
      } catch (error) {
        setStatusCode(error?.response?.status)
        setErrorCode(error?.response?.data?.errors?.detail || 'UNKNOWN_ERROR')
      }
    },
    [lkWebApi, onSuccess, petitionId, statusCodeToBolStep],
  )
  return (
    <div data-testid="bol-form-otp">
      <p className="lk-bol-new-user__title">{t('widgetBolNewUser.subtitle.otp')}</p>
      <WidgetBolBaseForm
        fields={['otp']}
        identifier={identifier}
        onFormSubmit={handleSubmit}
        submitLabel={t('widgetBolNewUser.sendForm')}
        showLegalCheckbox={false}
      />
      <LkCaptcha ref={captchaRef} />
      <div className="lk-bol-new-user__help">
        <div className="lk-bol-new-user__help-title">{t('widgetBolNewUser.otpHelp.title')}</div>
        <div>{t('widgetBolNewUser.otpHelp.legend')}</div>
        <div className="lk-bol-new-user__help-boxes">
          {helpBoxes.map(({ key, url, target, onClick }) => (
            <HelpBox
              key={`otpHelp${key}`}
              title={t(`widgetBolNewUser.otpHelp.${key}Title`)}
              body={t(`widgetBolNewUser.otpHelp.${key}Text`)}
              buttonText={t(`widgetBolNewUser.otpHelp.${key}Button`)}
              href={url ? url[language] : null}
              target={target}
              onClick={onClick}
            />
          ))}
        </div>
        <p
          className="bol-form-otp__help-text"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t(`widgetBolNewUser.otpHelp.telebankaText`),
          }}
        />
      </div>
      <BolApiErrorMessage
        errorCode={errorCode}
        onClose={() => {
          setErrorCode(null)
          if (statusCode === 404) {
            onSuccess(BolStep.IdForm, { identifier: '' })
          }
        }}
      />
      <AccessibleModal
        active={showContactForm}
        heading={t(`widgetBolNewUser.otpHelp.youngerTitle`)}
        fixed
        onClose={() => setShowContactForm(false)}
        size="medium"
      >
        <FinishBetween14And17 />
      </AccessibleModal>
    </div>
  )
}

export default OtpForm
