const allowedKeys = [
  'product_category',
  'product_subcategory',
  'product_name',
  'product_subproduct',
  'product_variant',
  'product_empresas',
  'product_personas',
  'page_origin',
]

export const widgetCustomFormEvents = {
  general: {
    event: 'generate_lead',
    contact_type: 'form',
    contact_category: 'form_product',
  },
  status: {
    ok: 'ok',
    ko: 'ko',
  },
}

export default allowedKeys
