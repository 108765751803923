import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import React, { useEffect, useState } from 'react'

import { getSanityClientLkConfig } from '../../../../lib/lkWebConfig'
import SanitySchema from '../../../../lib/types/sanity-schema'

export interface BannerImageProps {
  illustration: SanitySchema.Illustration
}

const BannerImageAsideImage: React.FC<BannerImageProps> = ({ illustration }) => {
  const breakpoints = useBreakpoint()
  const [imageSrc, setImageSrc] = useState('')
  const [imageAlt, setImageAlt] = useState('')
  useEffect(() => {
    let bannerImageWidth = 675
    let bannerImageHeight = 598
    if (breakpoints.desktop) {
      bannerImageWidth = 675
      bannerImageHeight = 598
    }
    if (breakpoints.tablet) {
      bannerImageWidth = 320
      bannerImageHeight = 564
    }
    if (breakpoints.mobile) {
      bannerImageWidth = 345
      bannerImageHeight = 115
    }
    const { image } = illustration
    const imageData = getGatsbyImageData(
      image,
      { width: bannerImageWidth, height: bannerImageHeight },
      getSanityClientLkConfig(),
    )
    const { src } = imageData?.images?.fallback || { src: '' }
    setImageSrc(src)
    setImageAlt(image?.alt)
  }, [breakpoints, illustration])

  return <img className="lk-hero__image" src={imageSrc} alt={imageAlt} title={imageAlt} />
}

export default BannerImageAsideImage
