import { Checkbox } from '@lk/lk-design-system'
import React, { FC } from 'react'

interface InsuranceCalculatorOfferBoxProps {
  children: React.ReactElement | React.ReactElement[]
  selected: boolean
  onClick: () => void
  label: string
}

const InsuranceCalculatorOfferBox: FC<InsuranceCalculatorOfferBoxProps> = ({ children, selected, onClick, label }) => (
  <div
    className={`lk-calculator__content lk-widget-insurance-calculator__offer bg-berenjena-claro-4 ${
      selected ? 'selected' : 'not-selected'
    }`}
  >
    {children}
    <Checkbox
      className="lk-widget-insurance-calculator__checkbox"
      checked={selected}
      aria-hidden="true"
      label={label}
      tabIndex={-1}
      onClick={(event) => {
        event.preventDefault()
        onClick()
      }}
    />
  </div>
)

export default InsuranceCalculatorOfferBox
